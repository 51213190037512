import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { ConfarenceMenberSelectionComponent } from './admin-dashboard/user-list/confarence_menber_selection/confarence-menber-selection/confarence-menber-selection.component';
import { CardsBooksPosterDetailsComponent } from './common-components/cards-books-poster-details/cards-books-poster-details.component';
import { BlogManagemntComponent } from './blog-managemnt/blog-managemnt.component';
import { AddBlogComponent } from './blog-managemnt/add-blog/add-blog.component';
import { CommonPreviewModalComponent } from './common-components/common-preview-modal/common-preview-modal.component';
import { AddBrodcastingComponent } from './brod-casting/add-brodcasting/add-brodcasting.component';
import { EditBrodcastingComponent } from './brod-casting/list-brodcasting/edit-brodcasting.component';
import { ContentPreviewComponent } from './common-components/content-preview/content-preview.component';





// import { HorosceopComComponent } from './horosceop-com/horosceop-com.component';

// import { AstroHeaderComponent } from './layout/astro-header/astro-header.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfarenceMenberSelectionComponent,
    CardsBooksPosterDetailsComponent,
    BlogManagemntComponent,
    AddBlogComponent,
    CommonPreviewModalComponent,
    AddBrodcastingComponent,
    EditBrodcastingComponent,
    ContentPreviewComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatProgressBarModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],

  providers: [AuthGuardService, AuthService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
})
export class AppModule { }
