import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appImageLazyLoader]'
})
export class ImageLazyLoaderDirective implements OnInit {
  @Input() appImageLazyLoader: string;  // Image URL
  private isImageLoaded: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.setLoader();  // Show loader initially
    this.loadImage();  // Attempt to load the image
  }

  private setLoader() {
    // Set a CSS loader while the image is loading
    const loader = this.renderer.createElement('div');
    this.renderer.setStyle(loader, 'position', 'absolute');
    this.renderer.setStyle(loader, 'top', '50%');
    this.renderer.setStyle(loader, 'left', '50%');
    this.renderer.setStyle(loader, 'transform', 'translate(-50%, -50%)');
    this.renderer.setStyle(loader, 'border', '4px solid transparent');
    this.renderer.setStyle(loader, 'borderTop', '4px solid #3498db');
    this.renderer.setStyle(loader, 'borderRadius', '50%');
    this.renderer.setStyle(loader, 'width', '30px');
    this.renderer.setStyle(loader, 'height', '30px');
    this.renderer.setStyle(loader, 'animation', 'spin 1s linear infinite');
    
    this.renderer.appendChild(this.el.nativeElement, loader);
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');  // To position the loader correctly
  }

  private loadImage() {
    const image = new Image();
    image.src = this.appImageLazyLoader;
      
    // When the image loads successfully, replace the loader with the image
    image.onload = () => {
      this.isImageLoaded = true;
      this.insertImage(image.src);
      this.removeLoader();
    };

    // If the image fails to load, keep the loader or provide a fallback
    image.onerror = () => {
      console.error('Image failed to load');
      this.removeLoader();  // You can optionally log or handle failure
    };
  }

  private insertImage(src: string) {
    // Create an image element and append it to the host element
    const img = this.renderer.createElement('img');
    this.renderer.setAttribute(img, 'src', src);
    this.renderer.setStyle(img, 'width', '100%');
    this.renderer.setStyle(img, 'height', '100%');
    this.renderer.setStyle(img, 'object-fit', 'cover');  // Make sure it scales appropriately
    this.renderer.appendChild(this.el.nativeElement, img);
  }

  private removeLoader() {
    // Remove the loader once the image is successfully loaded
    const loader = this.el.nativeElement.querySelector('div');
    if (loader) {
      this.renderer.removeChild(this.el.nativeElement, loader);
    }
  }
}
