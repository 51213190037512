<div class="customer_preview_modal">
  <h2 class="customer_preview_modal_heading">Content Preview</h2>
  <ul class="customer_preview_modal_listing">
    <li *ngIf="data.title">
      <span class="title"> Title : </span>
      <span>{{ data?.title ? data.title : "N/A" }}</span>
    </li>
    <li *ngIf="data.description">
      <span class="title">Description :</span>
      <span>{{ data?.description ? data.description : "N/A" }}</span>
    </li>
    <li *ngIf="data.content_type">
      <span class="title">Content Type : </span>
      <span>{{ data?.content_type ? data.content_type : "N/A" }}</span>
    </li>
    <li *ngIf="data.content_status">
        <span class="title">Content Status : </span>
        <span>{{ data?.content_status ? data.content_status : "N/A" }}</span>
      </li>
    <li *ngIf="data.access_control">
      <span class="title">Access Control : </span>
      <span>{{ data?.access_control ? data.access_control : "N/A" }}</span>
    </li>
    <li *ngIf="data.duration">
        <span class="title">Duration : </span>
        <span>{{ data?.duration ? data.duration : "N/A" }} minutes</span>
      </li>
      <li *ngIf="data.reminder_time">
        <span class="title">Reminder Time : </span>
        <span>{{ data?.reminder_time ? data.reminder_time : "N/A" }} minutes before</span>
      </li>
      <li *ngIf="data.reminder_method">
        <span class="title">Reminder Method : </span>
        <span>{{ data?.reminder_method ? data.reminder_method : "N/A" }}</span>
      </li>
    <li *ngIf="data.tags">
        <span class="title">Tag : </span>
        <span>{{ data?.tags ? data.tags : "N/A" }}</span>
      </li>
    <li *ngIf="data.priority">
      <span class="title">Priority :</span>
      <span>{{
        data?.priority ? data.priority : "N/A"
      }}</span>
    </li>
    
    <li *ngIf="data.file_path && (data.file_path.type.includes('video') || data.file_path.name.includes('MP4'))">
        <span *ngIf="data.file_path" class="title image_title">Video :</span>
        <span *ngIf="data.file_path" class="image_wraper">

                  <video controls [poster]="'https://d37pbainmkhx6o.cloudfront.net/'+ data.thumbnail_path.baseurl + data.thumbnail_path.fileservername">
                    <source [src]="'https://d37pbainmkhx6o.cloudfront.net/'+data.file_path.baseurl + data.file_path.fileservername" >
                  </video>

                
        </span>
    </li>

    

        <li *ngIf="data.file_path && (data.file_path.type.includes('application') || data.file_path.type.includes('pdf'))">
        <span *ngIf="data.file_path" class="title image_title">File :</span>
        <span *ngIf="data.file_path" class="image_wraper">
              <a style="color: #ff7e00;"
                [href]="'https://d37pbainmkhx6o.cloudfront.net/' + data.file_path.baseurl + data.file_path.fileservername"
                target="_blank"
                rel="noopener noreferrer"
                class="file_link"
              >
                {{ data.file_path.name || 'Download File' }}
              </a>

        </span>
      </li>

      <li *ngIf="data.direct_url ">
        <span *ngIf="data.direct_url" class="title image_title">Live Stream URL :</span>
        <span *ngIf="data.direct_url" class="image_wraper">
              <a style="color: #ff7e00;"
                [href]="data.direct_url"
                target="_blank"
                rel="noopener noreferrer"
                class="file_link"
              >
                {{ 'Attend Live Stream' }}
              </a>

        </span>
      </li>
      

    <li *ngIf="data.youtube_url">
      <span class="title image_title">YouTube Video :</span>
      <span class="image_wraper">
        <section>

            <iframe
    [src]="sanitizedYouTubeUrl"
    width="265"
    height="150"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
        </section>
      </span>
    </li>

    <li *ngIf="data.video_url">
        <span class="title image_title">Video :</span>
        <span class="image_wraper">
            <video controls [poster]="'https://d37pbainmkhx6o.cloudfront.net/'+ data.thumbnail_path.baseurl + data.thumbnail_path.fileservername">
                <source [src]="data.video_url" >
              </video>
          
        </span>
      </li>



    <li>
      <span class="title">Content Added On :</span>
      <span>{{ data?.display_dates ? create_on : "N/A" }}</span>
    </li>

    <!-- <li>
        <span class="title">Updated On :</span>
        <span>{{data?.updated_on ? updated_on :"N/A"}}</span>
      </li> -->
  </ul>
</div>
<!-- <button class="close_button" (click)="closeDialog()">X</button> -->
<span
  class="close-btn-modal material-icons close_button"
  (click)="closeDialog()"
  matTooltip="Close"
  matTooltipPosition="below"
>
  close
</span>
