import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ListingAngular15Module } from 'listing-angular15';
import { DemoMaterialModule } from '../material/material.module';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginInfoComponent } from '../common-components/login-info/login-info.component';
import { TarotCardModule } from '../tarot-card/tarot-card.module';
import { CardSpreadComponent } from '../tarot-card/card-spread/card-spread.component';
import { CardSpread2Component } from '../tarot-card/card-spread2/card-spread2.component';
import { CardSpread3Component } from '../tarot-card/card-spread3/card-spread3.component';
import { CardSpread4Component } from '../tarot-card/card-spread4/card-spread4.component';
import { CardSpread5Component } from '../tarot-card/card-spread5/card-spread5.component';
import { CardSpread6Component } from '../tarot-card/card-spread6/card-spread6.component';
import { CardSpread7Component } from '../tarot-card/card-spread7/card-spread7.component';
import { CardSpread8Component } from '../tarot-card/card-spread8/card-spread8.component';
import { CardSpread9Component } from '../tarot-card/card-spread9/card-spread9.component';
import { TarotMenuComponent } from '../layout/tarot-menu/tarot-menu.component';
import { ImageLazyLoaderDirective } from '../directiv/lazyloding.directive';
 





@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    //  MainComponent,
    // DashboardComponent,
    // AddUserComponent,
    // AddEditTrainingCategoryComponent,
    // TrainingCategoryComponent,
    // LessonComponent,
    // AddEditLessonComponent,
    // AstrologyComponent,
    // UserListComponent,
    // MainDashboardComponent,
    // AstroHeaderComponent,
    LoginInfoComponent,
    TarotMenuComponent,

    CardSpreadComponent,
    CardSpread2Component,
    CardSpread3Component,
    CardSpread4Component,
    CardSpread5Component,
    CardSpread6Component,
    CardSpread7Component,
    CardSpread8Component,
    CardSpread9Component,
    ImageLazyLoaderDirective

  ],
  imports: [
    CommonModule,
    ListingAngular15Module,
    DemoMaterialModule,
    ReactiveFormsModule,
    TarotCardModule,
    
    

  ],
  exports: [
    ListingAngular15Module,
    DemoMaterialModule,
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    LoginInfoComponent,
    CommonModule,
    ImageLazyLoaderDirective
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  
  
})
export class SharedModule { }
