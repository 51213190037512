import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.scss']
})
export class ContentPreviewComponent {

  public sanitizedYouTubeUrl: SafeResourceUrl;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<ContentPreviewComponent>, private sanitizer: DomSanitizer) {
    console.log("data---------------", data);
    
  }
    public tabledatatalist: any = [];
    public create_on:any
    public updated_on:any
  
    ngOnInit() {
    this.create_on=moment(this.data.display_dates).format('MMMM Do YYYY, h:mm:ss a');
    this.sanitizedYouTubeUrl = this.getSanitizedYouTubeUrl(this.data.youtube_url);
    }

    getSanitizedYouTubeUrl(url: string): SafeResourceUrl {
      const videoId = this.extractYouTubeVideoId(url);
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }

    extractYouTubeVideoId(url: string): string {
      
      const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/);      
      return videoIdMatch ? videoIdMatch[1] : '';
    }
  
    closeDialog() {
    
      this.dialogRef.close();
    }

}
